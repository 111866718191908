import React from "react";
import Img from "gatsby-image";
import BulmaMDXProvider from "./bulma-mdx-provider";
import ExternalLink from "./external-link"

const OtherWorkEntry = ({ item }) => (
  <article className="media">
    <figure className="media-left">
      <div className="image is-128x128">
        {item.frontmatter.featuredImage !== null && (
          <Img
            fluid={item.frontmatter.featuredImage.childImageSharp.fluid}
          ></Img>
        )}
      </div>
    </figure>
    <div className="media-content">
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <h4 className="title is-4">{item.frontmatter.title}</h4>
          </div>
          <div className="level-item">
            <h6 className="subtitle is-6">
              {item.frontmatter.projectStartDate}
            </h6>
          </div>
        </div>
      </div>
      <div className="content">
        <BulmaMDXProvider>{item.body}</BulmaMDXProvider>
        <ExternalLink href={item.frontmatter.url}>{item.frontmatter.url}</ExternalLink>
      </div>
    </div>
  </article>
);

export default OtherWorkEntry;
