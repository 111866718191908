import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PortfolioItemCardLayout from "../components/portfolio-item-card-layout";
import PortfolioOtherWork from "../components/portfolio-other-work";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import SEO from "../components/seo";
import RicardoCv from "../pdfs/cv_ricardo_garcia_silva.pdf";

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Portfolio" description="My work portfolio" />
      <div className="section">
        <div className="container">
          <h2 className="title">Portfolio</h2>
          <div className="content">
            <div className="columns">
              <div className="column is-9">
                <p>
                  I am an experienced software engineer with a strong GIS
                  background. <br />
                  My main area of expertise is in building backend solutions for
                  web-based systems that deal with spatial data.
                </p>
                <p>
                  This is a brief sample of past projects where I have worked.
                  <br />
                  Wherever possible I include some extra links for more
                  information.
                </p>
                <p>
                  I love talking about this stuff, so feel free to contact me
                  for further details on any of these entries.
                </p>
              </div>
              <div className="column">
                <div className="card has-background-light">
                  <div className="card-content">
                    <p className="title is-4">Curriculum Vitae</p>
                    <div className="media">
                      <div className="media-left">
                        <a
                          href={RicardoCv}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="curriculum vitae"
                          className="has-text-strong-blue"
                        >
                          <span className="icon is-large">
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              size="3x"
                              fixedWidth
                            />
                          </span>
                        </a>
                      </div>
                      <div className="media-content">
                        <p className="subtitle is-6">
                          Grab my CV for a more formal introduction to my
                          professional skills
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.withDate.nodes.map((element) => {
        let result = null;
        if (element.frontmatter.published === true) {
          result = <PortfolioItemCardLayout item={element} key={element.id} />;
        }
        return result;
      })}
      <PortfolioOtherWork />
    </Layout>
  );
};

export const query = graphql`
  query {
    withDate: allMdx(
      filter: {
        fileAbsolutePath: { regex: "//portfolio/(?!other-work)/" }
        frontmatter: { title: { regex: "/^(?!other)/i" } }
      }
      sort: {
        fields: [frontmatter___projectEndDate, frontmatter___date]
        order: [DESC, DESC]
      }
    ) {
      nodes {
        id
        body
        frontmatter {
          title
          date
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          projectStartDate
          projectEndDate
          codeRepositories
          externalLinks
          tags
          published
        }
      }
    }
  }
`;
