import React from "react";
import PropTypes from "prop-types";
import ExternalLink from "./external-link"

const TagList = ({ tagNames }) => (
  <div className="tags">
    {React.Children.map(tagNames, (tagName) => {
      const tagParts = tagName.split(",");
      const [url] = tagParts.slice(-1);
      return (
        <span className="tag has-background-strong-blue has-text-light">
            <ExternalLink className="has-text-light" href={url.trim()}>{tagParts[0]}</ExternalLink>
        </span>
      );
    })}
  </div>
);

TagList.propTypes = {
  tagNames: PropTypes.array.isRequired,
};

export default TagList;
