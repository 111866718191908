import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import OtherWorkEntry from "../components/other-work-entry";

const PortfolioOtherWork = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fileAbsolutePath: { regex: "//portfolio/other-work//" } }
        sort: {
          fields: [frontmatter___projectEndDate, frontmatter___date]
          order: [DESC, DESC]
        }
      ) {
        nodes {
          id
          body
          frontmatter {
            title
            url
            projectStartDate
            projectEndDate
            published
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <section className="section has-background-secondary-blue">
      <div className="container">
        <div className="card">
          <header className="card-header has-background-primary">
            <div className="card-header-title">
              <h3 className="title has-text-light">Other relevant work</h3>
            </div>
          </header>
          <div className="card-content">
            {data.allMdx.nodes.map((element) => {
              let result = null;
              if (element.frontmatter.published === true) {
                result = <OtherWorkEntry item={element} key={element.id} />;
              }
              return result;
            })}
          </div>
          <footer className="card-footer">
            <a
              className="card-footer-item"
              onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
            >
              To top
            </a>
          </footer>
        </div>
      </div>
    </section>
  );
};

export default PortfolioOtherWork;
