import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import TagList from "../components/tag-list";
import PortfolioItemLinks from "../components/portfolio-item-links";
import BulmaMDXProvider from "../components/bulma-mdx-provider";

const PortfolioItem = ({ item }) => {
  let subtitle = item.frontmatter.projectEndDate;
  if (item.frontmatter.projectStartDate !== item.frontmatter.projectEndDate) {
    subtitle = `${item.frontmatter.projectStartDate} - ${item.frontmatter.projectEndDate}`;
  }

  return (
    <div className="section has-background-secondary-blue" key={item.id}>
      <div className="container">
        <div className="card">
          <header className="card-header has-background-primary">
            <div className="card-header-title">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h3 className="title has-text-light">
                      {item.frontmatter.title}
                    </h3>
                  </div>
                  <div className="level-item">
                    <span className="subtitle has-text-light">{subtitle}</span>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="card-image">
            <figure className="image">
              <Img
                fluid={item.frontmatter.featuredImage.childImageSharp.fluid}
              />
            </figure>
          </div>
          <div className="card-content">
            <br />
            <div className="columns">
              <div className="column is-8">
                <div className="content">
                  <BulmaMDXProvider>{item.body}</BulmaMDXProvider>
                </div>
              </div>
              <div className="column">
                <h4 className="title is-4">Technology stack</h4>
                <TagList tagNames={item.frontmatter.tags} />
                <PortfolioItemLinks
                  codeRepositories={item.frontmatter.codeRepositories}
                  externalLinks={item.frontmatter.externalLinks}
                />
              </div>
            </div>
          </div>
          <footer className="card-footer">
            <a
              className="card-footer-item"
              onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
            >
              To top
            </a>
          </footer>
        </div>
      </div>
    </div>
  );
};

PortfolioItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PortfolioItem;
