import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeBranch,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import ExternalLink from "../components/external-link";

const PortfolioItemLinks = ({ codeRepositories, externalLinks }) => (
  <aside className="panel is-primary">
    <p className="panel-heading">More info</p>
    {React.Children.map(codeRepositories, (repoUrl) => (
      <ExternalLink className="panel-block" href={repoUrl}>
        <FontAwesomeIcon icon={faCodeBranch} fixedWidth />
        {repoUrl}
      </ExternalLink>
    ))}
    {React.Children.map(externalLinks, (externalUrl) => (
      <ExternalLink className="panel-block" href={externalUrl}>
        <FontAwesomeIcon icon={faLink} fixedWidth />
        {externalUrl}
      </ExternalLink>
    ))}
  </aside>
);

PortfolioItemLinks.propTypes = {
  codeRepositories: PropTypes.array,
  externalLinks: PropTypes.array,
};

export default PortfolioItemLinks;
