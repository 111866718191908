import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import ExternalLink from "./external-link"

const BulmaMDXProvider = ({ children }) => (
  <MDXProvider
    components={{
      ExternalLink,
      h1: (props) => (
        <h1 {...props} className="title is-1 has-text-primary"></h1>
      ),
      h2: (props) => (
        <h2 {...props} className="title is-2 has-text-primary"></h2>
      ),
      h3: (props) => (
        <h3 {...props} className="title is-3 has-text-primary"></h3>
      ),
      h4: (props) => (
        <h4 {...props} className="title is-4 has-text-primary"></h4>
      ),
      h5: (props) => (
        <h5 {...props} className="title is-5 has-text-primary"></h5>
      ),
      h6: (props) => (
        <h6 {...props} className="title is-6 has-text-primary"></h6>
      ),
    }}
  >
    <MDXRenderer>{children}</MDXRenderer>
  </MDXProvider>
);

export default BulmaMDXProvider